<template>
  <ul class="be-timeline">
    <li v-for="(event, index) in events" :key="index" class="be-timeline-item">
      <div class="be-timeline-item-tail" />

      <div :class="['be-timeline-item-bullet', bulletClass]">
        <slot name="bullet" :event="event" />
      </div>

      <div :class="['be-timeline-item-content', contentClass]">
        <slot name="content" :event="event">
          <template v-if="typeof event === 'string'">
            {{ event }}
          </template>
        </slot>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  name: "BeTimeline",

  props: {
    bulletClass: {
      type: [String, Object, Array],
      required: false,
      default: "",
    },

    contentClass: {
      type: [String, Object, Array],
      required: false,
      default: "",
    },

    events: {
      type: Array,
      required: true,
    },
  },
};
</script>
