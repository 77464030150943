<template>
  <div
    v-click-outside="onClickOutside"
    :class="computedClasses"
    role="combobox"
    :aria-expanded="dropdownOpen"
    aria-haspopup="listbox"
  >
    <custom-select-button
      :id="id"
      ref="button"
      :aria-controls="dropdownId"
      @deselect-option="onDeselectOption"
      @keydown="onKeydown"
      @toggle-dropdown="toggleDropdown"
    >
      <template #tag="tagScope">
        <slot name="tag" v-bind="tagScope" />
      </template>

      <template #selected="selectedScope">
        <slot name="selected" v-bind="selectedScope" />
      </template>
    </custom-select-button>

    <Transition name="be-custom-select" @after-leave="destroyPopper">
      <custom-select-dropdown
        v-show="dropdownOpen"
        :id="dropdownId"
        ref="dropdown"
        @highlight-option="highlightOption"
        @option-click="onOptionClick"
      >
        <template #search>
          <custom-select-search-input
            ref="searchInput"
            v-model="searchQuery"
            @keydown="onKeydown"
            @input="onSearch"
          />
        </template>

        <template #option="optionScope">
          <slot name="option" v-bind="optionScope" />
        </template>

        <template #dropdown-footer>
          <slot name="dropdown-footer" />
        </template>
      </custom-select-dropdown>
    </Transition>

    <!-- Hidden input to store the selected value -->
    <input
      :id="`${id}-input`"
      type="hidden"
      :name="name"
      :value="selected ? selected[optionValue] : null"
    />
  </div>
</template>

<script>
import Fuse from "fuse.js";
import Popper from "popper.js";
import FormStateMixin from "@/mixins/forms/form-state.js";
import { flattenOptions } from "@/components/shared/be_form_select/utils";
import { generateId } from "@/utils/id";
import { EventBus } from "@/event-bus";
import { computed } from "vue";
import {
  KEY_CODE_DOWN,
  KEY_CODE_ENTER,
  KEY_CODE_ESCAPE,
  KEY_CODE_TAB,
  KEY_CODE_UP,
} from "@/constants/key-codes";

import CustomSelectButton from "@/components/shared/be_form_select/CustomSelectButton.vue";
import CustomSelectDropdown from "@/components/shared/be_form_select/CustomSelectDropdown.vue";
import CustomSelectSearchInput from "@/components/shared/be_form_select/CustomSelectSearchInput.vue";

const requestAnimationFrame =
  window.requestAnimationFrame ||
  window.mozRequestAnimationFrame ||
  window.webkitRequestAnimationFrame ||
  window.msRequestAnimationFrame;

export default {
  name: "CustomSelect",

  components: {
    CustomSelectButton,
    CustomSelectDropdown,
    CustomSelectSearchInput,
  },

  mixins: [FormStateMixin],

  inject: ["asyncSearch"],

  provide() {
    return {
      disabled: computed(() => this.disabled),
      dropdownOpen: computed(() => this.dropdownOpen),
      groupSelectable: computed(() => this.groupSelectable),
      filteredOptions: computed(() => this.filteredOptions),
      highlighted: computed(() => this.highlighted),
      maxHeight: computed(() => this.maxHeight),
      multiple: computed(() => this.multiple),
      emptySearchResultsText: computed(() => this.emptySearchResultsText),
      emptyOptionsText: computed(() => this.emptyOptionsText),
      optionLabel: computed(() => this.optionLabel),
      optionValue: computed(() => this.optionValue),
      searchEnabled: computed(() => this.searchEnabled),
      searchQuery: computed(() => this.searchQuery),
      selected: computed(() => this.selected),
      stateClass: computed(() => this.stateClass),
    };
  },

  props: {
    ariaInvalid: {
      type: [Boolean, String],
      required: false,
      default: null,
    },

    autofocus: {
      type: Boolean,
      required: false,
      default: null,
    },

    boundary: {
      type: [String, HTMLElement],
      required: false,
      default: "scrollParent",
    },

    clearable: {
      type: Boolean,
      required: false,
      default: null,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: null,
    },

    emptySearchResultsText: {
      type: String,
      required: false,
      default: undefined,
    },

    emptyOptionsText: {
      type: String,
      required: false,
      default: undefined,
    },

    fuseKeys: {
      type: Array,
      required: false,
      default: null,
    },

    groupSelectable: {
      type: Boolean,
      required: false,
      default: null,
    },

    id: {
      type: String,
      required: true,
    },

    maxHeight: {
      type: String,
      required: false,
      default: "20rem",
    },

    modelValue: {
      type: undefined,
      required: false,
      default: null,
    },

    multiple: {
      type: Boolean,
      required: false,
      default: null,
    },

    name: {
      type: String,
      required: false,
      default: "",
    },

    options: {
      type: Array,
      required: false,
      default: () => [],
    },

    optionLabel: {
      type: String,
      required: false,
      default: "label",
    },

    optionValue: {
      type: String,
      required: false,
      default: "value",
    },

    popperOpts: {
      type: Object,
      required: false,
      default: () => ({}),
    },

    required: {
      type: Boolean,
      required: false,
      default: null,
    },

    searchable: {
      type: Boolean,
      required: false,
      default: true,
    },

    searchableThreshold: {
      type: [Number, String],
      required: false,
      default: 10,
    },

    size: {
      type: String,
      required: false,
      default: null,

      validator: (value) => ["sm", "lg"].includes(value),
    },
  },

  emits: ["change", "close", "open", "search", "update:modelValue"],

  data() {
    return {
      dropdownId: generateId("be-form-select-dropdown"),
      dropdownOpen: false,
      highlighted: null,
      searchQuery: "",
      selected: this.setSelected(),
    };
  },

  computed: {
    computedClasses() {
      return [
        "be-form-custom-select",
        { [`be-form-custom-select-${this.size}`]: this.size },
      ];
    },

    flatOptions() {
      return flattenOptions(this.options, this.optionLabel);
    },

    filteredOptions() {
      // Do not search with Fuse if asyncSearch is provided
      if (this.asyncSearch) {
        return this.options;
      }

      const fuse = new Fuse(this.flatOptions, {
        keys: this.fuseKeys || [this.optionLabel, "group"],
        threshold: 0.3,
        findAllMatches: true,
      });

      // Perform the search using Fuse.js or return all options if no search query is present
      const searchResults = this.searchQuery
        ? fuse.search(this.searchQuery)
        : this.flatOptions.map((item) => ({ item }));

      // Filter out already selected options, and hide group headers if all nested options are selected
      return (
        searchResults
          .filter(({ item: option }) => {
            // Hide suggested options from search
            if (this.searchQuery && option.suggestion) {
              return false;
            }

            // If in single select mode, return all options
            if (!this.multiple) {
              return true;
            }

            // Check if the option is already selected
            const isSelected = this.selected.some(
              (selectedOption) =>
                selectedOption[this.optionValue] === option[this.optionValue]
            );

            // If the option is a group header, check if all nested options are selected
            const isEveryGroupOptionSelected =
              option.options &&
              option.options.every((groupOption) =>
                this.selected.some(
                  (selectedOption) =>
                    selectedOption[this.optionValue] ===
                    groupOption[this.optionValue]
                )
              );

            // The option is included if it's not selected and not every group option is selected
            return !isSelected && !isEveryGroupOptionSelected;
          })
          // Map the search results to the original options
          .map(({ item }) => item)
      );
    },

    popperConfig() {
      return {
        placement: "bottom-start",

        modifiers: {
          flip: {
            behavior: ["bottom", "top"],
          },

          offset: {
            offset: "0, 3",
          },

          preventOverflow: {
            boundariesElement: this.boundary,
          },

          ...this.popperOpts.modifiers,
        },

        ...this.popperOpts,
      };
    },

    searchEnabled() {
      // If `asyncSearch` is provided, always enable search
      if (this.asyncSearch) {
        return true;
      } else if (
        // If `searchable` is enabled and the number of options exceeds the threshold, enable search
        this.searchable &&
        this.flatOptions.length > Number(this.searchableThreshold)
      ) {
        return true;
      } else {
        return false;
      }
    },
  },

  watch: {
    modelValue: {
      handler() {
        this.selected = this.setSelected();
      },

      deep: true,
    },
  },

  created() {
    this.$_popper = null;
  },

  mounted() {
    if (this.autofocus) {
      this.$refs.button.$el.click();
    }
  },

  methods: {
    closeDropdown() {
      if (!this.dropdownOpen) {
        return;
      }

      // Set the dropdown open flag
      this.dropdownOpen = false;

      // Emit the close event
      this.emitEvent("close");

      // Clear search query
      this.searchQuery = "";
    },

    createPopper(element) {
      this.destroyPopper();
      this.$_popper = new Popper(
        element,
        this.$refs.dropdown.$el,
        this.popperConfig
      );
    },

    destroyPopper() {
      if (this.$_popper) {
        this.$_popper.destroy();
        this.$_popper = null;
      }
    },

    emitEvent(type) {
      this.$emit(type, this);
      EventBus.emit(`be::select::${type}`, this);
    },

    emitSelection() {
      let value = null;

      if (this.multiple) {
        value = this.selected.map((option) => option[this.optionValue]);
      } else {
        value = this.selected ? this.selected[this.optionValue] : null;
      }

      this.$emit("update:modelValue", value);
      this.$emit("change", value);
    },

    handleMultipleSelection(option) {
      const isGroup = option.options;

      if (isGroup && this.groupSelectable) {
        this.selectGroupOptions(option);
      } else {
        this.selectOrDeselectOption(option);
      }
    },

    handleSingleSelection(option) {
      if (this.clearable && this.isOptionSelected(option)) {
        this.selected = null;
      } else {
        this.selected = option;
      }

      this.closeDropdown();
    },

    highlightNext(event, direction) {
      event.preventDefault();
      event.stopPropagation();

      const options = this.filteredOptions;

      if (options.length < 1) {
        return;
      }

      let index = this.highlighted;

      if (direction === "up") {
        index = index - 1;
        if (index < 0) {
          index = options.length - 1;
        }
      } else {
        index = index + 1;
        if (index >= options.length) {
          index = 0;
        }
      }

      // Skip groups that are not selectable
      while (
        (options[index].options && !(this.multiple && this.groupSelectable)) ||
        options[index].disabled
      ) {
        index = direction === "up" ? index - 1 : index + 1;
        if (index < 0) {
          index = options.length - 1;
        } else if (index >= options.length) {
          index = 0;
        }
      }

      this.highlighted = index;
    },

    highlightOption(index) {
      const option = this.filteredOptions[index];
      const isGroup = option.options;
      const canSelectGroup = this.multiple && this.groupSelectable;

      // If the option is a group header and the group is not selectable,
      // or the option is disabled, move to the next option
      if ((isGroup && !canSelectGroup) || option.disabled) {
        this.highlighted = index + 1;
      } else {
        this.highlighted = index;
      }
    },

    isOptionSelected(option) {
      if (this.multiple) {
        return this.selected?.some(
          (selectedOption) =>
            selectedOption[this.optionValue] === option[this.optionValue]
        );
      } else {
        return (
          this.selected &&
          this.selected[this.optionValue] === option[this.optionValue]
        );
      }
    },

    onClickOutside() {
      if (this.dropdownOpen) {
        this.closeDropdown();
      }
    },

    onDeselectOption(option) {
      this.selectOrDeselectOption(option);
      this.emitSelection();
      this.updatePopper();
    },

    onEnter(event) {
      event.preventDefault();
      event.stopPropagation();

      if (this.dropdownOpen && this.highlighted !== null) {
        this.onOptionClick(this.filteredOptions[this.highlighted]);
      } else {
        this.openDropdown();
      }
    },

    onEsc(event) {
      event.preventDefault();
      event.stopPropagation();

      this.closeDropdown();
      this.$refs.button.$el.focus();
    },

    onKeydown(event) {
      const { keyCode } = event;

      if (keyCode === KEY_CODE_ESCAPE) {
        this.onEsc(event);
      } else if (keyCode === KEY_CODE_ENTER) {
        this.onEnter(event);
      } else if (keyCode === KEY_CODE_TAB) {
        if (this.dropdownOpen) {
          this.highlightNext(event, "down");
        }
      } else if (keyCode === KEY_CODE_DOWN) {
        this.highlightNext(event, "down");
      } else if (keyCode === KEY_CODE_UP) {
        this.highlightNext(event, "up");
      }
    },

    onOptionClick(option) {
      if (this.multiple) {
        this.handleMultipleSelection(option);
        this.updatePopper();
      } else {
        this.handleSingleSelection(option);
      }

      this.emitSelection();
    },

    onSearch(searchQuery) {
      this.$emit("search", searchQuery);
    },

    openDropdown() {
      if (this.disabled) {
        return;
      }

      // Make sure the Popper.js library is available
      if (typeof Popper === "undefined") {
        console.warn(
          "BeFormCombobox: Popper.js not found. Falling back to CSS positioning."
        );
      } else {
        // Initialize the Popper instance
        this.createPopper(this.$el);
      }

      // Update the Popper instance to get the correct position
      this.updatePopper();

      // Set the dropdown open flag
      this.dropdownOpen = true;

      // Emit the open event
      this.emitEvent("open");

      // Wait for the next tick to be sure that the DOM is ready
      this.$nextTick(() => {
        // Focus the search input when the dropdown is opened, if it is enabled
        if (this.searchEnabled) {
          this.$refs.searchInput.$el.querySelector("input").focus();
        }

        // Highlight the first option, if there are any
        if (this.filteredOptions.length > 0) {
          this.highlightOption(0);
        }

        // Enable listener to close this select when another is opened
        EventBus.on("be::select::open", (select) => {
          if (select !== this) {
            this.closeDropdown();
          }
        });
      });
    },

    reset() {
      this.selected = this.setSelected();
    },

    selectGroupOptions(option) {
      const groupOptions = option.options.filter(
        (groupOption) => !this.isOptionSelected(groupOption)
      );

      this.selected = this.selected.concat(groupOptions);
    },

    selectOrDeselectOption(option) {
      const isSelected = this.isOptionSelected(option);

      if (isSelected) {
        this.selected = this.selected.filter(
          (selectedOption) =>
            selectedOption[this.optionValue] !== option[this.optionValue]
        );
      } else {
        this.selected.push(option);
      }
    },

    setSelected() {
      // NOTE: We do a loose equality check here because the modelValue can be a string or number
      // and might not match the type of the option value, but should still be considered a match.

      if (this.multiple) {
        return (this.modelValue || [])
          .map((value) =>
            flattenOptions(this.options, this.optionLabel).find(
              (option) => option[this.optionValue] == value
            )
          )
          .filter(Boolean);
      } else {
        return (
          flattenOptions(this.options, this.optionLabel).find(
            (option) => option[this.optionValue] == this.modelValue
          ) || null
        );
      }
    },

    toggleDropdown() {
      if (this.dropdownOpen) {
        this.closeDropdown();
      } else {
        this.openDropdown();
      }
    },

    updatePopper() {
      if (this.$_popper) {
        requestAnimationFrame(() => {
          this.$_popper.update();
        });
      }
    },
  },
};
</script>
